import { Component } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  constructor(private meta: Meta,
              private title: Title) {

    this.meta.addTags(MetaTags.ABOUT_TAGS);
    this.setTitle('About Us | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
