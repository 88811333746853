<ng-container *ngIf="true; then signIn">
</ng-container>

<ng-container *ngIf="true; then signUp">
</ng-container>

<ng-container *ngIf="true; then personalInformation">
</ng-container>

<ng-container *ngIf="true; then addressDetails">
</ng-container>

<ng-container *ngIf="true; then orderCompletedView">
</ng-container>


<ng-template #signIn>
  <modal id="deal-application-journey">
    <div class="modal-header">
      <h5 class="modal-title">Account</h5>
      <button type="button" class="btn-close" (click)="modalService.closeAll()"></button>
    </div>
    <div class="modal-body">
      <div class="login-header">
        <p class="account-pretext">Contact details
          <button [hidden]="true" class="text-button"  (click)="modalService.next('new-account-sign-up');signUpNewAccount(true)">Sign
            up
          </button>
        </p>
      </div>
      <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
        <div class="row">
          <div class="col-md-12">
            <label>Email</label>
            <input type="email" formControlName="email" class="form-control">
            <div *ngIf="isInvalidControl('email', getSignUpForm())"
                 class="alert alert-danger">
              <div>Please enter a valid email address</div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" (click)="modalService.close();">Back</button>
      <button type="button" class="btn btn-primary modal-btn-primary"
              (click)="createCallMeAccount(['email'], 'deal-application-journey', 'personal-information-modal')">Continue
      </button>
    </div>
  </modal>
</ng-template>

<ng-template #signUp>
  <modal id="new-account-sign-up">

    <div class="modal-header">
      <h5 class="modal-title">New Account</h5>
      <button type="button" class="btn-close" (click)="modalService.closeAll()"></button>
    </div>
    <div class="modal-body">
      <div class="login-header">
        <p class="account-pretext">Already have an account?
          <button class="text-button" (click)="modalService.next('deal-application-journey');signUpNewAccount(false)">
            login
          </button>
        </p>
      </div>
      <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
        <div class="row">
          <div class="col-md-12">
            <label>Email</label>
            <input type="email" formControlName="email" class="form-control">
            <div *ngIf="isInvalidControl('email', getSignUpForm())"
                 class="alert alert-danger">
              <div>Email is required.</div>
            </div>
            <div *ngIf="duplicateUserExist"
                 class="alert alert-danger">
              <div>Username already exist.</div>
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control">
            <div *ngIf="isInvalidControl('password', getSignUpForm())"
                 class="alert alert-danger">
              <div>Password is required.</div>
            </div>
          </div>
          <div class="col-md-6 mt-3">
            <label>Confirm password</label>
            <input type="password" formControlName="confirmPassword"
                   class="form-control">
            <div *ngIf="isInvalidControl('confirmPassword', getSignUpForm())"
                 class="alert alert-danger">
              <div>{{isPasswordMatch() ? '' : 'Passwords must match'}}</div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" (click)="modalService.previous();">Back</button>
      <button type="button" class="btn btn-primary modal-btn-primary"
              (click)="signUpToNewAccount()">Continue
      </button>
    </div>
  </modal>
</ng-template>

<ng-template #personalInformation>
  <modal id="personal-information-modal">
    <div class="modal-header">
      <h5 class="modal-title">Account personal details</h5>
      <button type="button" class="btn-close" (click)="modalService.closeAll()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
        <div class="row">
          <div class="col-md-6">
            <p class="bold">Personal Information</p>
            <div class="mt-3">
              <label for="first-name">First Name</label>
              <input type="text" id="first-name" class="form-control" formControlName="firstName">
              <div *ngIf="isInvalidControl('firstName', getSignUpForm())"
                   class="alert alert-danger">
                <div>First Name is required.</div>
              </div>
            </div>
            <div class="mt-3">
              <label for="last-name">Last Name</label>
              <input type="text" id="last-name" class="form-control" formControlName="lastName">
              <div *ngIf="isInvalidControl('lastName', getSignUpForm())"
                   class="alert alert-danger">
                <div>Last Name is required.</div>
              </div>
            </div>
            <div class="mt-3">
              <label for="contact">Contact Number</label>
              <input type="text" id="contact" class="form-control"
                     formControlName="contactNumber">
              <div *ngIf="isInvalidControl('contactNumber', getSignUpForm())"
                   class="alert alert-danger">
                <div>Please enter a valid 10-digit South African cellphone number.</div>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="false">
            <p class="bold">Identification</p>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-sa-id-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-sa-id" type="button" role="tab"
                        aria-controls="pills-sa-id" aria-selected="true" (click)="setIdentificationType('RSA_ID')">South
                  African ID
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-passport-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-passport" type="button" role="tab"
                        aria-controls="pills-passport" aria-selected="false"
                        (click)="setIdentificationType('PASSPORT')">
                  Passport
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent" formGroupName="legalReference">
              <div class="tab-pane fade show active" id="pills-sa-id" role="tabpanel"
                   aria-labelledby="pills-sa-id-tab" tabindex="0">
                <label for="south-africa-id">South African ID Number</label>
                <input type="text" id="south-africa-id" formControlName="value"
                       class="form-control">
                <div *ngIf="isInvalidControl('value', this.registerForm.get('legalReference'))"
                     class="alert alert-danger">
                  Legal reference is required.
                </div>
              </div>
              <div class="tab-pane fade" id="pills-passport" role="tabpanel"
                   aria-labelledby="pills-passport-tab" tabindex="0">
                <div class="mt-3">
                  <label for="passport">Passport Number</label>
                  <input type="text" id="passport" formControlName="value" class="form-control">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary"
              (click)="modalService.previous()">
        Back
      </button>
      <button type="button" class="btn btn-primary modal-btn-primary"
              (click)="updateAccountDetails(['firstName', 'lastName', 'contactNumber'], 'address-details-modal');">Continue
      </button>
    </div>
  </modal>
</ng-template>

<ng-template #addressDetails>
  <modal id="address-details-modal">

    <div class="modal-header">
      <h5 class="modal-title">Application details</h5>
      <button type="button" class="btn-close" (click)="modalService.closeAll()"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="registerForm" (click)="patchRegisterFormWithCookies()">
        <div class="row" formGroupName="address">
          <div class="col-md-6">
            <p class="bold">Line location details</p>
            <div class="map-location">
              {{registerForm.get('address.address')?.value}}
            </div>
            <div class="mt-3">
              <label for="addressType">Address Type:</label>
              <select id="addressType" formControlName="addressType" class="form-control">
                <option value="">Select Address Type</option>
                <option value="home">Home</option>
                <option value="work">Work</option>
                <option value="other">Other</option>
              </select>
              <div *ngIf="registerForm.get('address.addressType')?.errors
        && (registerForm.get('address.addressType')?.dirty || registerForm.get('address.addressType')?.touched)">Address
                Type is required.
              </div>
            </div>
            <div class="mt-3">
              <label for="unit-number">Unit Number</label>
              <input type="number" id="unit-number" formControlName="unitNumber"
                     class="form-control">

              <div *ngIf="registerForm.get('address.unitNumber')?.errors
          && (registerForm.get('address.unitNumber')?.dirty || registerForm.get('address.unitNumber')?.touched)">
                unit/house number is required.
              </div>
            </div>
            <div class="mt-3">
              <label for="complex-name">Complex Name</label>
              <input type="text" id="complex-name" formControlName="complexName"
                     class="form-control">
            </div>

          </div>
          <div class="col-md-6">
            <p class="bold">Installation Details</p>
            <div class="mt-3">
              <select id="location" class="form-control" formControlName="orderType">
                <option [defaultSelected]="true" value="New installation">New installation</option>
                <option value="Migration">Migration</option>
                <option value="New contract">New contract</option>
                <option value="Upgrade contract">Upgrade contract</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
            <div class="mt-3">
              <label for="address">Address</label>
              <input type="tel" id="address" formControlName="address" class="form-control" required>
              Address is required
            </div>
            <div class="mt-3">
              <label for="street">Street</label>
              <input type="tel" id="street" formControlName="street" class="form-control">
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary" (click)="modalService.previous()">
        Back
      </button>
      <button type="button" class="btn btn-primary modal-btn-primary"
              (click)="completeAddressDetails(['address', 'unitNumber', 'complexName']);">
        Continue
      </button>
    </div>
  </modal>
</ng-template>

<ng-template #orderCompletedView>
  <modal id="order-completed">
    <div class="modal-header">
      <h5 class="modal-title">Confirmation</h5>
      <button type="button" class="btn-close" (click)="modalService.closeAll()"></button>
    </div>
    <div class="modal-body">
      <div style="text-align:center;">
        <h2>Order completed</h2>
        <p>We will call or email you to update you about your order.</p>
      </div>
    </div>
    <div class="modal-footer justify-content-between" *ngIf="orderCompleted">
      <button type="button" class="btn btn-secondary"
              (click)="modalService.previous()">
        Back
      </button>
      <button type="button" class="btn btn-primary modal-btn-primary"
              data-bs-dismiss="modal" (click)="modalService.closeAll()">
        Close
      </button>
    </div>
  </modal>
</ng-template>

