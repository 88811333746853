<header class="privacy-header">
  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>Blog Details</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/blog">Blog </a>/
    <a routerLink="/about-details">Blog Details</a>

  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Blog Details Section Start -->
<section id="blog-details">
  <div class="container">
    <div class="blog-details-head text-center">
      <h6>12th August 2020</h6>
      <h4>
        Are Your Maps Keeping You On The Road to Efficiency?
      </h4>
    </div>
    <div class="blog-details-preview">
      <p class="d-none d-md-block">
        <i class="fa-solid fa-user"></i> By: Admin
      </p>
      <p>
        <i class="fa-solid fa-thumbs-up"></i>
        2.5k Likes
      </p>
      <p><i class="fa-solid fa-message"></i> 1.5k Comments</p>
    </div>
    <div class="blog-details-img">
      <img
        src="https://images.unsplash.com/photo-1644088379091-d574269d422f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1693&q=80"
        alt=""
      />
    </div>
    <p>
      <span>Before</span> Jesus left this earth, he gave a pretty
      clear directive: go and make disciples. Sure, there’s a lot
      more depth to that passage, but that’s the headline. Just
      like Chevrolet makes automobiles, just like McDonald’s makes
      hamburgers, and just like Dr Pepper makes a perfectly
      balanced 23-flavor drink, Christians make disciples. Now, I
      don’t know about you, but I’ve heard people list off what to
      look for in someone that you might disciple. Honestly, I’ve
      searched out those lists because I wanted to spend my time
      strategically.
    </p>
    <p>
      These lists usually go something like this: find someone who
      is teachable, available, and faithful. We wouldn’t say this
      out loud, but it practically boils down to, “grab the best
      Christian you can find and make them a better Christian.”
      Quite frankly this always bothered me, and I wasn’t able to
      figure out why until I stumbled across a poem by Rumi, a
      13th century Sufi mystic. Here’s part of what he wrote:
    </p>
    <p>
      As Christians, disciple-making is our craft. Why on earth
      then would we seek out the best Christians to make them
      better?
      <strong
      >That would be the equivalent of a painter sitting down
        and painting over an existing picture instead of
        starting with a blank canvas</strong
      >. Just as a builder looks for a hole, we should seek to
      disciple the people that most need Jesus. It’s not as easy,
      and you won’t get to take credit for mentoring an already
      strong Christian, but I think that’s what Jesus would have
      done. After all, didn’t he say that God was like a shepherd
      that would leave 99 sheep safely behind while he sought out
      the missing one? And if I remember correctly, Jesus didn’t
      pick his disciples from the cream of the crop; he sat down
      to eat with folks that the religious leaders wouldn’t go
      near.
    </p>
    <p>
      I’ll tell you how I identify people to disciple. I lead a
      small group (for high school students) on Sunday nights, I
      open my house for them to hang out on Wednesday nights, and
      periodically I invite people to join me in what I’m already
      doing – like watching a movie. All I do is invite people and
      I watch to see who shows up consistently. Typically, that’s
      not the kids who are running after Jesus, it’s the ones
      running away from dysfunction at home or in other parts of
      their lives. It’s kind of like Jesus sitting by a well and
      waiting for someone to show up who desperately needs to draw
      water at an odd time because she’s the talk of the town.
    </p>
    <p>
      [1] Rumi. “Craftsmanship and Emptiness.
      <i
      >” The Essential Rumi. Edited by Coleman Barks, 24. New
        York: HarperCollins, 1995.</i
      >
    </p>
  </div>
</section>
<!-- ||Blog Details Section End|| -->

<!-- Blog Comment Section Start -->
<section id="blog-comment">
  <div class="container">
    <div class="comment-heading">
      <h5>Comments</h5>
    </div>
    <div class="comments">
      <div class="com-item">
        <div class="com-icon">
          <i class="fa-solid fa-user-nurse"></i>
        </div>
        <div class="com-text">
          <h6>Asikur Rahaman</h6>
          <p>
            Udit Khanna is a Digital Marketing Course
            professional at Expert Training Institute, an
            expert in Digital Marketing, Search Engine
            Optimization, Pay Per Click, Social Media, etc.
          </p>
        </div>
      </div>
      <div class="com-item">
        <div class="com-icon">
          <i class="fa-solid fa-user-nurse"></i>
        </div>
        <div class="com-text">
          <h6>Abrara Rahaman</h6>
          <p>
            I will try some of these sites on this Blog
            Commenting site exactly what I look for. Such
            good information shared with as these sites are
            really helpful for my website for doing SEO
            thanks for sharing your post.
          </p>
        </div>
      </div>
      <div class="comment-box">
        <div class="row align-items-center">
          <div class="">
            <input type="text" placeholder="Full Name" />
            <textarea
              name=""
              placeholder="Write a comment.."
            ></textarea>
          </div>
          <div class="">
            <button>
              <a href="">comment</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ||Blog Comment Section End|| -->
