import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
  styleUrls: ['./cookie-consent-modal.component.scss']
})
export class CookieConsentModalComponent implements OnInit, OnDestroy {
  @Output() acceptCookies = new EventEmitter<void>();
  @Output() declineCookies = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  accept() {
    this.acceptCookies.emit();
  }

  decline() {
    this.declineCookies.emit();
  }
}
