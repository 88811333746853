import {Component} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {Constants} from "../../constants/constants";
import {DomSanitizer, Meta, SafeUrl, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";
import {ApiService} from "../../services/api.service";

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}
interface BrochureData {
  pageId: number;
  sectionId: number;
  brochureUrl: string;
}
@Component({
  selector: 'app-lte-and-sim',
  templateUrl: './lte-and-sim.component.html',
  styleUrls: ['./lte-and-sim.component.scss']
})
export class LteAndSimComponent {
  public lead: any;
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  public isDataLoadedWithoutError: boolean = false;
  constructor(public modalService: ModalService,
              private meta: Meta,
              private title: Title,
              private apiService: ApiService,
              private sanitizer: DomSanitizer) {

    this.meta.addTags(MetaTags.LTE_AND_SIM_TAGS);
    this.setTitle('Business Internet LTE & SIM | Apprentice Valley Digital');
    this.lead = {
      leadType: 'website',
      type: Constants.LTE_AND_SIM_DEAL
    }
  }

  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }

  ngOnInit(): void {
    const pageId = 5; // Replace with the actual pageId
    this.fetchSectionData(pageId, 1); // Section 1
    this.fetchSectionData(pageId, 2); // Section 2
    this.fetchSectionData(pageId, 3); // Section 3
    this.fetchSectionData(pageId, 4); // Section 3
    this.fetchSectionData(pageId, 5); // Section 3
    this.fetchSectionData(pageId, 6); // Section 3
    this.fetchSectionData(pageId, 7); // Section 3
  }

  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }
  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }

  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data; // Use sectionId as the index
        this.sectionBrochureUrls[sectionId] = data.brochureUrl; // Use sectionId as the index
        this.isDataLoadedWithoutError = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.sectionBannerImageUrls[sectionId] = safeUrl; // Use sectionId as the index
  }
}
