import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class Constants {
  public static readonly COMMUNICATION_DEAL = 'COMMUNICATION_DEAL';
  public static readonly LTE_AND_SIM_DEAL = 'LTE_AND_SIM_DEAL';
  public static readonly SATELLITE_AND_WIRELESS_AIR = 'SATELLITE_AND_WIRELESS_AIR';
}
