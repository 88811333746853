import { Component } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
  constructor(private meta: Meta,
              private title: Title) {

    this.meta.addTags(MetaTags.BLOG_TAGS);
    this.setTitle('Blog | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
