import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit  {

  showMap:boolean=false
  ngOnInit(): void {
  setTimeout(() => {
    this.showMap=true
  }, 3000);
  }

}
