<!-- Header Section Start -->
<header class="privacy-header">

  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>News and updates</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/home">Home </a>/
    <a routerLink="/blog">Blog</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Blog Section Start -->
<section id="blog">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-1.jpg" alt="Telecommunication tower" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-2.jpg" alt="" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a  routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-1.jpg" alt="" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a  routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-2.jpg" alt="" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a  routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-1.jpg" alt="" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a  routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="blog-content">
          <div class="blog-img">
            <img src="assets/images/net-2.jpg" alt="" />
            <div class="blog-overlay">
              <img
                src="assets/images/logo-new.png"
                alt=""
              />
            </div>
          </div>
          <div class="blog-text">
            <h3>Investing in Our Smart Cities</h3>
            <div class="blog-preview">
              <p class="d-none d-md-block">
                <i class="fa-solid fa-user"></i> By:
                Admin
              </p>
              <p>
                <i class="fa-solid fa-thumbs-up"></i>
                2.5k Likes
              </p>
              <p>
                <i class="fa-solid fa-message"></i> 1.5k
                Comments
              </p>
            </div>
            <p>
              Yes, it is true that in today's world,
              customer focus is a critical factor in
              successful business and smart city planning.
              And there are a multitude of avenues that
              need to be considered before any project can
              go ahead, such as: Infrastructure...
            </p>

            <div class="blog-btn">
              <a  routerLink="/blog-details">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next-page" [hidden]="true">
      <button><a href="">Next Page</a></button>
    </div>
  </div>
</section>
<!-- ||Blog Section End|| -->

<!-- Footer Section Start -->
