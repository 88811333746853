<ng-container *ngIf="isDataLoadedWithoutError; else defaultContent">
<!-- section 1 -->

<div class="section-1">
  <div class="section-1-img">
    <img *ngIf="sectionBannerImageUrls[1]" [src]="sectionBannerImageUrls[1]" alt="Business Contract Deals for Phones, Laptops, and More | Apprentice Valley Digital header image">
  </div>
  <div class="black-gradient">
    <div class="section-1-text">
      <h1 class="section-1-heading">{{ sectionData[1]?.headerTitle }}</h1>
      <p class="section-1-detail">{{ sectionData[1]?.headerDescription }}</p>
    </div>
  </div>
</div>

<!-- explore section -->

<div class="explore-container">
  <div class="explore text-center">
    <h1 class="bold">Explore our deal range</h1>
    <p class="bold">Use the filters below to find exactly what you're looking for</p>
    <div class="deal-btns d-flex justify-content-between mt-4">
      <button class="deal-btn {{phoneAndSim && !tabletsAndLaptops ? 'active' : ''}}" (click)="onPhoneAndSimSelect(true); resetAllNotMobile()">Phone & SIM</button>
      <button class="deal-btn {{!phoneAndSim && tabletsAndLaptops ? 'active' : ''}}" (click)="onTabletsAndLaptopsSelect(true); resetAllNotLaptop()">Laptop & Tablets</button>
    </div>
  </div>
  <div class="mobile-deals text-center">
    <img class="img-fluid" src="/assets/images/mobile-brands.png" alt="Picture of a phone">
  </div>
</div>

<!-- products section -->

<div class="products-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-3">
        <div class="sticky-container">
          <div class="filters-container">
            <div class="filters text-center">
              <div class="categories mb-4">
              <h4>Categories</h4>
              <br>
              <div class="brand mb-4">
                <select id="device" style="width: 100%;" [(ngModel)]="selectedBrand" (change)="onBrandChange(selectedBrand)">
                  <option value="">All Brands</option>
                  <option *ngFor="let option of brandOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
              <div><label for="category">All Categoires ({{communicationsData?.length}})</label> <input id="category"
                                                                                                        type="checkbox"
                                                                                                        [(ngModel)]="allCategories"
                                                                                                        (change)="onAllCategorySelect(allCategories)">
              </div>
              <div>
                <label for="phoneAndSim">Phone and Sim ({{phoneAndSimTotal}})</label> <input id="phoneAndSim" type="checkbox"
                                                                            [(ngModel)]="phoneAndSim"
                                                                            (change)="onPhoneAndSimSelect(phoneAndSim)">
              </div>
              <div>
                <label for="tabletsAndLaptops">Tablets and Laptops ({{tabletsAndLaptopsTotal}})</label> <input id="tabletsAndLaptops"
                                                                                        type="checkbox"
                                                                                        [(ngModel)]="tabletsAndLaptops"
                                                                                        (change)="onTabletsAndLaptopsSelect(tabletsAndLaptops)">
              </div>

            </div>
            <div class="price mb-4">
              <h4>Price</h4>
              <div>
                <input type="range" [max]="10000" [min]="0" [step]="50" [(ngModel)]="maxPrice"
                       (change)="onPriceChange(maxPrice)" style="width: 100%;">
              </div>
              <div>{{maxPrice}}</div>
            </div>
            <div class="contract-duration mb-4">
              <h4>Contract Duration</h4>
              <div class="d-flex justify-content-evenly">
                <div>
                  <input type="checkbox" id="duration-1" [(ngModel)]="duration36" (change)="onDuration36(duration36)">
                </div>
                <div>
                  <label for="duration-1">36 Months</label>
                </div>
              </div>
              <div class="d-flex justify-content-evenly">
                <div>
                  <input type="checkbox" [(ngModel)]="duration24" (change)="onDuration24(duration24)" id="duration-2">
                </div>
                <div>
                  <label for="duration-2">24 Months</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-lg-10 col-md-9">
        <div class="row" style="margin-bottom: 30px;">
          <div class="col-lg-4 col-md-6 col-sm-12 mt-4" *ngFor="let deal of communicationsData | paginate: { itemsPerPage: 15, currentPage: p }">
            <div class="product-card-container">
              <div class="product-card text-center">
                <div class="product-img" *ngIf="deal.images?.length > 0; else stockImage">
                  <div id="{{deal.id}}" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item {{index == 0 ? 'active' : ''}}"
                           *ngFor="let image of deal.images; let index = index" style="height: 400px; ">
                        <img src="{{image}}.png" alt="" class="d-block w-100 aspect-ratio"
                             style="height: 400px; max-height: 100%; object-fit: contain;">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#'+deal.id"
                            data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#'+deal.id"
                            data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <ng-template #stockImage>
                  <img src="/assets/images/product-img.jpg" alt="" style="width: 283px;
                                    max-width: 100%;">
                </ng-template>
                <div class="contract-section">
                  <div class="row mt-2 justify-content-center">
                    <ng-container *ngFor="let option of deal.paymentOptions; let i = index">
                      <div class="col-md-{{ 12 / (deal.paymentOptions.length > maxPaymentOptionsToShow ? maxPaymentOptionsToShow : deal.paymentOptions.length) }} col-12 mb-3 card" *ngIf="(showAllFeaturesMap[deal.id] || i < maxPaymentOptionsToShow) && option.price && option.duration && option.unit">
                        <div class="text-center border-primary mt-1">
                          <p><b>{{ option?.unit === 'full' ? '' : option?.duration }} {{ option?.unit === 'full' ? 'price' : option?.unit }}</b></p>
                          <div class="text-muted fs-6 mb-0">R{{ option?.price }}</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="product-name text-center">
                  <ng-container *ngIf="showAllFeaturesMap[deal.id] || deal.name.length <= 23">
                    {{ deal.name }}
                  </ng-container>
                  <ng-container *ngIf="!showAllFeaturesMap[deal.id] && deal.name.length > 23">
                    {{ deal.name.slice(0, 23) + '...' }}
                  </ng-container>
                </div>
                <div class="product-specs text-center">
                  <div class="product-specs-container">
                    <div class="d-block h-100">
                      <ng-container *ngIf="deal.features.length > 0">
                        <ng-container *ngFor="let feature of deal.features; let i = index">
                          <ng-container *ngIf="showAllFeaturesMap[deal.id] || i < 2">
                            {{ showAllFeaturesMap[deal.id] || feature.length <= 23 ? feature : feature.slice(0, 23) + '...' }}<br>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="deal.features.length === 1">
                          <br>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="deal.features.length === 0">
                        <br><br>
                      </ng-container>
                      <button (click)="toggleShowMore(deal.id)" style="font-weight: bold; color: blue;">
                        {{ showAllFeaturesMap[deal.id] ? 'Show Less' : 'Show More' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apply">
                <button class="apply-btn" (click)="modalService.open('communications-deal-application-journey', {'dealId': deal.id, lead: lead})">Apply Now
                </button>
              </div>
            </div>
          </div>
          <div class="pagination-container d-flex justify-content-center align-items-center" style="margin-top: 30px;">
            <pagination-controls
              (pageChange)="p = $event"
              [directionLinks]="true"
              [responsive]="true"
              previousLabel="Prev"
              nextLabel="Next"
            ></pagination-controls>
          </div>

        </div>
        <!-- brochure button  -->
        <button class="brochure-btn" (click)="downloadBrochure(3)">Download the brochure</button>
      </div>
    </div>
  </div>
</div>

<communications-deal-application-journey/>
</ng-container>

<ng-template #defaultContent>
  <!-- section 1 -->

  <div class="section-1">
    <div class="section-1-img">
      <img src="/assets/images/business-contract-deals-section-1.png" alt="Business Contract Deals for Phones, Laptops, and More | Apprentice Valley Digital header image">
    </div>
    <div class="black-gradient">
      <div class="section-1-text">
        <h1 class="section-1-heading">COMMUNICATIONS</h1>
        <p class="section-1-detail">Communication solutions to suit your business needs</p>
      </div>
    </div>
  </div>

  <!-- explore section -->

  <div class="explore-container">
    <div class="explore text-center">
      <h1 class="bold">Explore our deal range</h1>
      <p class="bold">Use the filters below to find exactly what you're looking for</p>
      <div class="deal-btns d-flex justify-content-between mt-4">
        <button class="deal-btn {{phoneAndSim && !tabletsAndLaptops ? 'active' : ''}}" (click)="onPhoneAndSimSelect(true); resetAllNotMobile()">Phone & SIM</button>
        <button class="deal-btn {{!phoneAndSim && tabletsAndLaptops ? 'active' : ''}}" (click)="onTabletsAndLaptopsSelect(true); resetAllNotLaptop()">Laptop & Tablets</button>
      </div>
    </div>
    <div class="mobile-deals text-center">
      <img class="img-fluid" src="/assets/images/mobile-brands.png" alt="Picture of a phone">
    </div>
  </div>

  <!-- products section -->

  <div class="products-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 col-md-3">
          <div class="sticky-container">
            <div class="filters-container">
              <div class="filters text-center">
                <div class="categories mb-4">
                  <h4>Categories</h4>
                  <br>
                  <div class="brand mb-4">
                    <select id="device" style="width: 100%;" [(ngModel)]="selectedBrand" (change)="onBrandChange(selectedBrand)">
                      <option value="">All Brands</option>
                      <option *ngFor="let option of brandOptions" [value]="option">{{ option }}</option>
                    </select>
                  </div>
                  <div><label for="category">All Categoires ({{communicationsData?.length}})</label> <input id="category"
                                                                                                            type="checkbox"
                                                                                                            [(ngModel)]="allCategories"
                                                                                                            (change)="onAllCategorySelect(allCategories)">
                  </div>
                  <div>
                    <label for="phoneAndSim">Phone and Sim ({{phoneAndSimTotal}})</label> <input id="phoneAndSim" type="checkbox"
                                                                                                 [(ngModel)]="phoneAndSim"
                                                                                                 (change)="onPhoneAndSimSelect(phoneAndSim)">
                  </div>
                  <div>
                    <label for="tabletsAndLaptops">Tablets and Laptops ({{tabletsAndLaptopsTotal}})</label> <input id="tabletsAndLaptops"
                                                                                                                   type="checkbox"
                                                                                                                   [(ngModel)]="tabletsAndLaptops"
                                                                                                                   (change)="onTabletsAndLaptopsSelect(tabletsAndLaptops)">
                  </div>

                </div>
                <div class="price mb-4">
                  <h4>Price</h4>
                  <div>
                    <input type="range" [max]="10000" [min]="0" [step]="50" [(ngModel)]="maxPrice"
                           (change)="onPriceChange(maxPrice)" style="width: 100%;">
                  </div>
                  <div>{{maxPrice}}</div>
                </div>
                <div class="contract-duration mb-4">
                  <h4>Contract Duration</h4>
                  <div class="d-flex justify-content-evenly">
                    <div>
                      <input type="checkbox" id="duration-1" [(ngModel)]="duration36" (change)="onDuration36(duration36)">
                    </div>
                    <div>
                      <label for="duration-1">36 Months</label>
                    </div>
                  </div>
                  <div class="d-flex justify-content-evenly">
                    <div>
                      <input type="checkbox" [(ngModel)]="duration24" (change)="onDuration24(duration24)" id="duration-2">
                    </div>
                    <div>
                      <label for="duration-2">24 Months</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10 col-md-9">
          <div class="row" style="margin-bottom: 30px;">
            <div class="col-lg-4 col-md-6 col-sm-12 mt-4" *ngFor="let deal of communicationsData | paginate: { itemsPerPage: 15, currentPage: p }">
              <div class="product-card-container">
                <div class="product-card text-center">
                  <div class="product-img" *ngIf="deal.images?.length > 0; else stockImage">
                    <div id="{{deal.id}}" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item {{index == 0 ? 'active' : ''}}"
                             *ngFor="let image of deal.images; let index = index" style="height: 400px; ">
                          <img src="{{image}}.png" alt="" class="d-block w-100 aspect-ratio"
                               style="height: 400px; max-height: 100%; object-fit: contain;">
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#'+deal.id"
                              data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#'+deal.id"
                              data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <ng-template #stockImage>
                    <img src="/assets/images/product-img.jpg" alt="" style="width: 283px;
                                    max-width: 100%;">
                  </ng-template>
                  <div class="contract-section">
                    <div class="row mt-2 justify-content-center">
                      <ng-container *ngFor="let option of deal.paymentOptions; let i = index">
                        <div class="col-md-{{ 12 / (deal.paymentOptions.length > maxPaymentOptionsToShow ? maxPaymentOptionsToShow : deal.paymentOptions.length) }} col-12 mb-3 card" *ngIf="(showAllFeaturesMap[deal.id] || i < maxPaymentOptionsToShow) && option.price && option.duration && option.unit">
                          <div class="text-center border-primary mt-1">
                            <p><b>{{ option?.unit === 'full' ? '' : option?.duration }} {{ option?.unit === 'full' ? 'price' : option?.unit }}</b></p>
                            <div class="text-muted fs-6 mb-0">R{{ option?.price }}</div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="product-name text-center">
                    <ng-container *ngIf="showAllFeaturesMap[deal.id] || deal.name.length <= 23">
                      {{ deal.name }}
                    </ng-container>
                    <ng-container *ngIf="!showAllFeaturesMap[deal.id] && deal.name.length > 23">
                      {{ deal.name.slice(0, 23) + '...' }}
                    </ng-container>
                  </div>
                  <div class="product-specs text-center">
                    <div class="product-specs-container">
                      <div class="d-block h-100">
                        <ng-container *ngIf="deal.features.length > 0">
                          <ng-container *ngFor="let feature of deal.features; let i = index">
                            <ng-container *ngIf="showAllFeaturesMap[deal.id] || i < 2">
                              {{ showAllFeaturesMap[deal.id] || feature.length <= 23 ? feature : feature.slice(0, 23) + '...' }}<br>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="deal.features.length === 1">
                            <br>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="deal.features.length === 0">
                          <br><br>
                        </ng-container>
                        <button (click)="toggleShowMore(deal.id)" style="font-weight: bold; color: blue;">
                          {{ showAllFeaturesMap[deal.id] ? 'Show Less' : 'Show More' }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apply">
                  <button class="apply-btn" (click)="modalService.open('communications-deal-application-journey', {'dealId': deal.id, lead: lead})">Apply Now
                  </button>
                </div>
              </div>
            </div>
            <div class="pagination-container d-flex justify-content-center align-items-center" style="margin-top: 30px;">
              <pagination-controls
                (pageChange)="p = $event"
                [directionLinks]="true"
                [responsive]="true"
                previousLabel="Prev"
                nextLabel="Next"
              ></pagination-controls>
            </div>

          </div>
          <!-- brochure button  -->
          <button class="brochure-btn" (click)="defaultDownloadBrochure()">Download the brochure</button>
        </div>
      </div>
    </div>
  </div>

  <communications-deal-application-journey/>

  </ng-template>
