import { Injectable } from '@angular/core';
import {ModalComponent} from "../components/layout/modal/modal.component";


@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: ModalComponent[] = [];
  private previousModalIds: string[] = [];

  add(modal: ModalComponent) {
    // ensure component has a unique id attribute
    if (!modal.id || this.modals.find(x => x.id === modal.id)) {
      throw new Error('modal must have a unique id attribute');
    }

    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(modal: ModalComponent) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x !== modal);
  }

  open(id: string, data?: any) {
    // open modal specified by id
    const modal = this.modals.find(x => x.id === id);

    if (!modal) {
      throw new Error(`modal '${id}' not found`);
    }

    const currentlyOpenModal = this.modals.find(x => x.isOpen);
    if (currentlyOpenModal) {
      if (currentlyOpenModal.id != null) {
        this.previousModalIds.push(currentlyOpenModal.id);
      }
    }

    if (data) {
      modal.data = data;
    }
    modal.open();
  }

  retrieveModalData(id: string) {
    // open modal specified by id
    const modal = this.modals.find(x => x.id === id);

    if (!modal) {
      console.log(`modal '${id}' not found`);
      return null;
    }
    return modal.data;
  }

  close() {
    // close the modal that is currently open
    const modal = this.modals.find(x => x.isOpen);
    modal?.close();
  }

  closeAll() {
    // close all modal that is currently open
    this.modals.filter(x => x.isOpen).forEach(modal => modal?.close());
  }

  next(id: string) {
    console.log('next modalId: ', id);
    const currentModal = this.modals.find(x => x.isOpen);
    this.close();
    if (currentModal && currentModal.id) {
      this.previousModalIds.push(currentModal.id);
    }
    const nextModalData = this.retrieveModalData(id);
    this.open(id, nextModalData);
  }

  previous() {
    this.close();
    if (this.previousModalIds.length > 0) {
      const previousId = this.previousModalIds.pop();
      if (previousId) {
        this.open(previousId);
      }
    }
  }
}
