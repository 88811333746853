import {Component, OnDestroy, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environment/environment';
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'apprentice-valley';
  googleApiKey:string=''
  isLoading:boolean=true
  showCookieConsentModal: boolean = false;

  constructor(private router: Router, private cookieService: CookieService) {
    this.googleApiKey=environment.googleApiKey
    this.router.events.subscribe((ev) => {

      if (ev instanceof NavigationEnd) {
        if((ev.url==='/home#contact' || ev.urlAfterRedirects==='/home#contact') || (ev.url==='/home' || ev.urlAfterRedirects==='/home')){
          return
        }

        this.isLoading=true
        setTimeout(() => {
          this.isLoading=false
        }, 1000);
      }
    });
  }

  ngOnInit(): void {
    const googleApiUrl= `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places,geometry`
    const scriptTagGoogle= document.createElement('script')
    scriptTagGoogle.defer=true
    scriptTagGoogle.src= googleApiUrl
    document.getElementsByTagName('head')[0].appendChild(scriptTagGoogle)
    this.isLoading=true
    setTimeout(() => {
      this.isLoading=false
    }, 1000);

    if (!localStorage.getItem('cookieConsent')) {
      this.showCookieConsentModal = true;
    }
  }

  ngOnDestroy() {
    this.cookieService.deleteAll('/');
  }

  onAcceptAllCookies() {
    localStorage.setItem('cookieConsent', 'true');
    this.showCookieConsentModal = false;
  }

  onDeclineAllCookies() {
    localStorage.setItem('cookieConsent', 'false');
    this.showCookieConsentModal = false;
  }
}
