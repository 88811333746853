import { Component } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent {
  constructor(private meta: Meta,
              private title: Title) {

    this.meta.addTags(MetaTags.COMING_SOON_TAGS);
    this.setTitle('COMING SOON | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
