<header class="privacy-header">
  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>About Us</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/home">Home </a>/
    <a routerLink="/about">About Us</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- About Us Section Start -->
<section id="about-us">
  <div class="container">
    <div class="about">
      <div class="about-text">
        <h6>Apprentice Valley</h6>
        <p>
          At Apprentice Valley our digital solutions are
          designed to help accelerate business growth by using
          effective e-Commerce and Mobile solutions. We’re
          actively involved in the Telecommunications, Mobile
          and Financial industries. But our commitment is to
          provide expert technology solutions for all
          businesses, therefore, we have a growing footprint
          in VAS Content, Mobility, Courier Logistics, Digital
          Identity and Hospitality.
        </p>
      </div>
      <div class="about-img">
        <div class="a-img">
          <img src="assets/images/about-img.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ||About Us Section End|| -->

<!-- Partners Section Start -->
<section id="partners">
  <h2 class="text-center">Our Partners</h2>
  <div class="partners-container d-flex justify-content-around m-5">
    <div class="partners-item">
      <div class="partner-img">
        <img
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/ukheshe.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img part-mt">
        <img
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/fiskl.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img">
        <img
          class="bgg"
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/red2.png"
          alt="Partner Logo of red october"
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img part-mt">
        <img
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/digi.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img">
        <img
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/rush.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img part-mt">
        <img
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/bsc.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img">
        <img
          class="bgg"
          src="https://ocean76collective.com/img/logo/FINAL%20LOGO/simpli%20white.png"
          alt=""
        />
      </div>
    </div>
    <div class="partners-item">
      <div class="partner-img part-mt">
        <img
          src="https://ocean76collective.com/img/logo/gold/ocean2.png"
          alt=""
        />
      </div>
    </div>
  </div>
</section>
