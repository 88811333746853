<div class="modal">
  <div class="modal-content">
    <h2>Cookie Consent</h2>
    <p>This website uses cookies to ensure you get the best experience on our website. By clicking "Accept All", you consent to our use of cookies.</p>
    <p>Please review our <a href="/privacy-policy">Privacy Policy</a> for more information.</p>
    <div class="btn-container">
      <button class="accept-btn" (click)="accept()">Accept All</button>
      <button class="decline-btn" (click)="decline()">Decline All</button>
    </div>
  </div>
</div>
