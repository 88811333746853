import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { Meta, Title, DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MetaTags } from "../../constants/meta-data/meta.tags";
import { CookieService } from "ngx-cookie-service";

interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit {
  @ViewChild('contact') contactSection!: ElementRef;
  public contactForm: FormGroup = new FormGroup({});
  public submittedQuery: boolean = false;
  public sectionBannerImageUrls: SafeUrl[] = [];
  public sectionData: SectionData[] = [];
  public sectionBrochureUrls: string[] = [];
  public isDataLoadedWithoutError: boolean = false;

  private readonly emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.(com|co\.za|org|gov|edu|net|mil|biz|info|mobi|name|aero|jobs|museum)$/;
  private readonly emailValidator = Validators.pattern(this.emailPattern);

  private readonly phonePattern = /^(\+27|0)?\d{10}$/;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {
    this.meta.addTags(MetaTags.HOME_TAGS);
    this.setTitle('Welcome to Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    const pageId = 1; //Home page id
    this.fetchSectionData(pageId, 1);
    this.fetchSectionData(pageId, 2);
    this.fetchSectionData(pageId, 3);

    this.contactForm = new FormGroup({
      firstName: new FormControl(''),
      email: new FormControl('', [Validators.required, this.emailValidator]),
      accountType: new FormControl('customer_query'),
      contactNumber: new FormControl('', [this.validatePhoneNumber()]),
    });
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.fragment === 'contact') {
      this.contactSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onSubmit(): void {
    if (!this.contactForm.invalid) {
      this.submittedQuery = true;
      this.apiService.submitQuery(this.contactForm.value).subscribe(data => {
        this.submittedQuery = true;
        this.setPersistentCookies(this.contactForm.value);
        this.apiService.saveCookieData(this.contactForm.value);
      });
    }
  }

  patchContactFormWithCookies(): void {
    if (localStorage.getItem('cookieConsent') === 'true') {
      const formControls = this.contactForm.controls;
      for (const controlName in formControls) {
        if (formControls.hasOwnProperty(controlName)) {
          const control = formControls[controlName];
          const cookieValue = this.cookieService.get(controlName);
          if (cookieValue && control.untouched) {
            control.patchValue(cookieValue);
          }
        }
      }
    }
  }

  private setPersistentCookies(formData: any) {
    const cookieMap = {};
    this.traverse(formData, '', cookieMap);

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    Object.entries(cookieMap).forEach(([key, value]) => {
      const valueAsString = typeof value === "number" && key !== 'id' ? value.toString() : value;
      if (typeof valueAsString === "string") {
        this.cookieService.set(key, valueAsString, expirationDate, undefined, undefined, true);
      }
    });
  }

  private traverse(data: any, prefix: string, cookieMap: any): void {
    for (const [key, value] of Object.entries(data)) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        this.traverse(value, newKey, cookieMap);
      } else {
        cookieMap[newKey] = value;
      }
    }
  }
  private validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const phoneNumber = control.value;
      const phonePattern = /^(\+27|0)\d{9}$/;

      if (!phonePattern.test(phoneNumber)) {
        return { invalidPhoneNumber: true };
      }

      return null;
    };
  }

  defaultDownloadBrochure() {
    this.apiService.downloadFile('Connectivity_Solutions.pdf').subscribe();
  }
  downloadBrochure(sectionId: number) {
    const brochureUrl = this.sectionBrochureUrls[sectionId];
    if (brochureUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = brochureUrl;
      downloadLink.download = 'brochure.pdf';
      downloadLink.click();
    } else {
      console.error('No brochure URL available for the selected section.');
    }
  }

  private fetchSectionData(pageId: number, sectionId: number) {
    this.apiService.getSectionById(pageId, sectionId).subscribe(
      (data: SectionData) => {
        this.sectionData[sectionId] = data;
        this.sectionBrochureUrls[sectionId] = data.brochureUrl;
        this.isDataLoadedWithoutError = true;
      },
      (error) => {
        console.error('Error fetching section data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
    this.apiService.getBannerImageData(pageId, sectionId).subscribe(
      (imageData: Blob) => {
        const objectURL = URL.createObjectURL(imageData);
        this.setBannerImageUrl(sectionId, objectURL);
      },
      (error) => {
        console.error('Error fetching banner image data:', error);
        this.isDataLoadedWithoutError = false;
      }
    );
  }

  private setBannerImageUrl(sectionId: number, objectURL: string) {
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    this.sectionBannerImageUrls[sectionId] = safeUrl; // Use sectionId as the index
  }
}
