<!-- Header Section Start -->
<header class="privacy-header">

  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>Terms and Conditions</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a href="/home">Home </a>/
    <a routerLink="/terms-and-conditions">Terms and Conditions</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Terms and condition Section Start -->
<section id="privacy-containt">
  <div class="container">
    <div class="privacy-item">
      <h5>1. Introduction</h5>
      <p>
        Welcome to Apprentice Valley (“Company”, “we”, “our”,
        “us”)! These Terms of Service (“Terms”, “Terms of
        Service”) govern your use of our website located at
        www.apprenticevalleydigital.co.za (together or individually
        “Service”) operated by Apprentice Valley. Our Privacy
        Policy also governs your use of our Service and explains
        how we collect, safeguard and disclose information that
        results from your use of our web pages. Your agreement
        with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and
        understood Agreements, and agree to be bound of them. If
        you do not agree with (or cannot comply with)
        Agreements, then you may not use the Service, but please
        let us know by emailing at
        <a href="">info@apprentice.co.za</a> so we can try to
        find a solution. These Terms apply to all visitors,
        users and others who wish to access or use Service.
      </p>
    </div>
    <div class="privacy-item">
      <h5>2. Communications</h5>
      <p>
        By using our Service, you agree to subscribe to
        newsletters, marketing or promotional materials and
        other information we may send. However, you may opt out
        of receiving any, or all, of these communications from
        us by following the unsubscribe link or by emailing at
        <a href="">info@apprentice.co.za</a>.
      </p>
    </div>
    <div class="privacy-item">
      <h5>3. Contests, Sweepstakes and Promotions</h5>
      <p>
        Any contests, sweepstakes or other promotions
        (collectively, “Promotions”) made available through
        Service may be governed by rules that are separate from
        these Terms of Service. If you participate in any
        Promotions, please review the applicable rules as well
        as our Privacy Policy. If the rules for a Promotion
        conflict with these Terms of Service, Promotion rules
        will apply.
      </p>
    </div>
    <div class="privacy-item">
      <h5>4. Content</h5>
      <p>
        Our Service allows you to post, link, store, share and
        otherwise make available certain information, text,
        graphics, videos, or other material (“Content”). You are
        responsible for Content that you post on or through
        Service, including its legality, reliability, and
        appropriateness. By posting Content on or through
        Service, You represent and warrant that: (i) Content is
        yours (you own it) and/or you have the right to use it
        and the right to grant us the rights and license as
        provided in these Terms, and (ii) that the posting of
        your Content on or through Service does not violate the
        privacy rights, publicity rights, copyrights, contract
        rights or any other rights of any person or entity. We
        reserve the right to terminate the account of anyone
        found to be infringing on a copyright. You retain any
        and all of your rights to any Content you submit, post
        or display on or through Service and you are responsible
        for protecting those rights. We take no responsibility
        and assume no liability for Content you or any third
        party posts on or through Service. However, by posting
        Content using Service you grant us the right and license
        to use, modify, publicly perform, publicly display,
        reproduce, and distribute such Content on and through
        Service. You agree that this license includes the right
        for us to make your Content available to other users of
        Service, who may also use your Content subject to these
        Terms. Apprentice Valley has the right but not the
        obligation to monitor and edit all Content provided by
        users. In addition, Content found on or through this
        Service are the property of Apprentice Valley or used
        with permission. You may not distribute, modify,
        transmit, reuse, download, repost, copy, or use said
        Content, whether in whole or in part, for commercial
        purposes or for personal gain, without express advance
        written permission from us.
      </p>
    </div>
    <div class="privacy-item">
      <h5>5. Prohibited Uses</h5>
      <div class="item-con">
        <p>
          You may use Service only for lawful purposes and in
          accordance with Terms. You agree not to use Service:
        </p>
        <ul>
          <li>
            • In any way that violates any applicable
            national or international law or regulation.
          </li>
          <li>
            • For the purpose of exploiting, harming, or
            attempting to exploit or harm minors in any way
            by exposing them to inappropriate content or
            otherwise.
          </li>
          <li>
            • To transmit, or procure the sending of, any
            advertising or promotional material, including
            any “junk mail”, “chain letter,” “spam,” or any
            other similar solicitation.
          </li>
          <li>
            • To impersonate or attempt to impersonate
            Company, a Company employee, another user, or
            any other person or entity.
          </li>
          <li>
            • In any way that infringes upon the rights of
            others, or in any way is illegal, threatening,
            fraudulent, or harmful, or in connection with
            any unlawful, illegal, fraudulent, or harmful
            purpose or activity.
          </li>
        </ul>
      </div>
      <h6>• Additionally, you agree not to:</h6>
      <ul>
        <li>
          • Use Service in any manner that could disable,
          overburden, damage, or impair Service or interfere
          with any other party’s use of Service, including
          their ability to engage in real time activities
          through Service.
        </li>
        <li>
          • Use any robot, spider, or other automatic device,
          process, or means to access Service for any purpose,
          including monitoring or copying any of the material
          on Service.
        </li>
        <li>
          • Use any manual process to monitor or copy any of
          the material on Service or for any other
          unauthorized purpose without our prior written
          consent.
        </li>
        <li>
          • Use any device, software, or routine that
          interferes with the proper working of Service.
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- ||Terms and condition Section End|| -->

