import {Component} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {

  constructor(private meta: Meta,
              private title: Title) {

    this.meta.addTags(MetaTags.TERMS_AND_CONDITIONS_TAGS);
    this.setTitle('Terms and Conditions | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
