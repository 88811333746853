import { Component, ElementRef, ViewChild } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('contact') contactSection!: ElementRef;
  hideNavigationOptions = false;
  constructor(private router: Router, private route:ActivatedRoute) {
    this.router.events.subscribe((ev) => {

      if (ev instanceof NavigationEnd) {
        this.hideNavigationOptions=true;
        if((ev.url==='/home#contact' || ev.urlAfterRedirects==='/home#contact') || (ev.url==='/home' || ev.urlAfterRedirects==='/home')){
          return
        }
      }
    });
  }
  scrollToContact(): void {
    document.getElementById('contact')?.scrollIntoView({behavior:'smooth'})
    if (this.route.snapshot.fragment === 'contact') {
      document.getElementById('contact')?.scrollIntoView({behavior:'smooth'})
    }
    this.router.navigate(['/contact'], { fragment: 'contact' });

  }

  openNavigationBar() {
    this.hideNavigationOptions = false;
  }
}
