import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';


interface SectionData {
  sectionId: number;
  pageId: number;
  headerTitle: string;
  headerDescription: string;
  bannerUrl: string;
  brochureUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = ''

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl
  }

  getCommunicationsData(params: any) {
    const categories = Boolean(params.categories)
    const phonesAndSim = Boolean(params.phonesAndSim)
    const lteAndSim = Boolean(params.lteAndSim)
    const tabletsAndLaptops = Boolean(params.tabletsAndLaptops)
    const connectivity = Boolean(params.connectivity)
    const priceMin = (params.priceMin)
    const priceMax = (params.priceMax)
    const duration36 = Boolean(params.duration36)
    const duration24 = Boolean(params.duration24)
    const brand=(params.brand)

    return this.http.get(`${this.baseUrl}/product-solution-service/api/av-digital/deals?categories=${categories}&phonesAndSim=${phonesAndSim}&lteAndSim=${lteAndSim}&tabletsAndLaptops=${tabletsAndLaptops}&connectivity=${connectivity}&priceMin=${priceMin}&priceMax=${priceMax}&duration36=${duration36}&duration24=${duration24}&brand=${brand}`)
  }

  getAllProviders() {
    return this.http.get(`${this.baseUrl}/provider-service/api/av-digital/providers`)
  }

  getDealsData() {
    return this.http.get(`${this.baseUrl}/product-solution-service/api/av-digital/product-solutions?latitude=-25.957600&longitude=28.120990&solutionTypes=fibre`)
  }

  getPackageData(coords: any) {
    const lat = String(coords.lat)
    const lng = String(coords.lng)
    return this.http.get(`${this.baseUrl}/provider-service/api/av-digital/providers?latitude=${lat}&longitude=${lng}&solutionTypes=fibre`)
  }

  retrieveProvider(providerId: number) {
    return this.http.get(`${this.baseUrl}/product-solution-service/api/av-digital/product-solutions/provider/${providerId}`)
  }

  loginUser(username: string, password: string) {
    return this.http.get(`${this.baseUrl}/user-service/api/av-digital/users/login`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`)
      }
    })
  }

  createOrder(userId: number, body: any) {

    console.log('body', body)
    return this.http.post(`${this.baseUrl}/user-service/api/av-digital/users/${userId}/orders`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  }

  createUser(body: any) {
    console.log('body', body)
    return this.http.post(`${this.baseUrl}/user-service/api/av-digital/users`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(`${body.email}:${body.password}`)
      }
    })
  }

  createAnonUser(body: any) {
    console.log('body', body)
    return this.http.post(`${this.baseUrl}/user-service/api/av-digital/users/query`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(`${body.email}:${body.password}`)
      }
    })
  }

  submitQuery(body: any) {
    console.log('body', body)
    return this.http.post(`${this.baseUrl}/user-service/api/av-digital/users/query`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  }

  updateUser(userId: number, body: any) {
    console.log('body', body)
    return this.http.put(`${this.baseUrl}/user-service/api/av-digital/users/${userId}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa(`${body.email}:${body.password}`)
      }
    })
  }

  isUser(username: string) {
    return this.http.get(`${this.baseUrl}/user-service/api/av-digital/users/exist?username=`+username, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }
  getBrandOptions(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/product-solution-service/api/av-digital/deals/by-brand`);
  }
  downloadFile(fileName: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream'
    });
    const options = { headers, responseType: 'blob' as 'blob' };

    return this.http.get(`${this.baseUrl}/product-solution-service/api/av-digital/${fileName}`, options).pipe(
      tap((blob: Blob) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
    );
  }

  getBannerImageData(pageId: number,sectionId: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/product-solution-service/api/av-digital/banners/pages/${pageId}/sections/${sectionId}`, { responseType: 'blob' });
  }
  getSectionById(pageId: number, sectionId: number): Observable<SectionData> {
    return this.http.get<SectionData>(`${this.baseUrl}/product-solution-service/api/av-digital/cms/pages/${pageId}/sections/${sectionId}`);
  }

  saveCookieData(body: any) {
    this.http.post(`${this.baseUrl}/user-service/api/av-digital/users/cookies`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .subscribe(
        (response) => {
          console.log("Response: ", response);
        },
        (error) => {
          console.error("Error: ", error);
        }
      );
  }
}
