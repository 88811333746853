<!-- Header Section Start -->
<header class="privacy-header">

  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>Privacy Policy</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/home">Home </a>/
    <a routerLink="/privacy-policy">Privacy Policy</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Privacy Policy Section Start -->
<section id="privacy-containt">
  <div class="container">
    <div class="privacy-item">
      <h5>1. Introduction</h5>
      <h6>• Welcome to Apprentice Valley.</h6>
      <p>
        Apprentice Valley (“us”, “we”, or “our”) operates
        www.apprenticevalleydigital.co.za (hereinafter referred to as
        “Service”). Our Privacy Policy governs your visit to
        www.apprenticevalleydigital.co.za, and explains how we collect,
        safeguard and disclose information that results from
        your use of our Service. We use your data to provide and
        improve this Service. By using this Service, you agree to the
        collection and use of information in accordance with
        this policy. Unless otherwise defined in this Privacy
        Policy, the terms used in this Privacy Policy have the
        same meanings as in our Terms and Conditions. Our Terms
        and Conditions (“Terms”) govern all use of our Service
        and together with the Privacy Policy constitute your
        agreement with us (“agreement”).
      </p>
    </div>
    <div class="privacy-item">
      <h5>2. Definitions</h5>
      <p>
        SERVICE means the www.apprenticevalleydigital.co.za website operated
        by Apprentice Valley. PERSONAL DATA means data about a
        living individual who can be identified from those data
        (or from those and other information either in our
        possession or likely to come into our possession). USAGE
        DATA is data collected automatically either generated by
        the use of Service or from Service infrastructure itself
        (for example, the duration of a page visit). COOKIES are
        small files stored on your device (computer or mobile
        device). DATA CONTROLLER means a natural or legal person
        who (either alone or jointly or in common with other
        persons) determines the purposes for which and the
        manner in which any personal data are, or are to be,
        processed. For the purpose of this Privacy Policy, we
        are a Data Controller of your data. DATA PROCESSORS (OR
        SERVICE PROVIDERS) means any natural or legal person who
        processes the data on behalf of the Data Controller. We
        may use the services of various Service Providers in
        order to process your data more effectively. DATA
        SUBJECT is any living individual who is the subject of
        Personal Data. THE USER is the individual using our
        Service. The User corresponds to the Data Subject, who
        is the subject of Personal Data.
      </p>
    </div>
    <div class="privacy-item">
      <h5>3. Information Collection and Use</h5>
      <p>
        We collect several different types of information for
        various purposes to provide and improve our Service to
        you.
      </p>
    </div>
    <div class="privacy-item">
      <h5>4. Types of Data Collected</h5>
      <div class="item-con">
        <h6>• Personal Data</h6>
        <p>
          While using our Service, we may ask you to provide
          us with certain personally identifiable information
          that can be used to contact or identify you
          (“Personal Data”). Personally identifiable
          information may include, but is not limited to:
        </p>
        <ul>
          <li>• Email address</li>
          <li>• Phone number</li>
          <li>• First name and last name</li>
          <li>• Cookies and Usage Data</li>
          <li>
            • Address, Country, State, Province, ZIP/Postal
            code, City
          </li>
        </ul>
        <p>
          We may use your Personal Data to contact you with
          newsletters, marketing or promotional materials and
          other information that may be of interest to you.
          You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe
          link.
        </p>
      </div>
      <div class="item-con">
        <h6>• Usage Data</h6>
        <p>
          We may also collect information that your browser
          sends whenever you visit our Service or when you
          access Service by or through any device (“Usage
          Data”). This Usage Data may include information such
          as your computer’s Internet Protocol address (e.g.
          IP address), browser type, browser version, the
          pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages,
          unique device identifiers and other diagnostic data.
          When you access Service with a device, this Usage
          Data may include information such as the type of
          device you use, your device unique ID, the IP
          address of your device, your device operating
          system, the type of Internet browser you use, unique
          device identifiers and other diagnostic data.
        </p>
      </div>
      <div class="item-con">
        <h6>• Location Data</h6>
        <p>
          We may use and store information about your location
          if you give us permission to do so (“Location
          Data”). We use this data to provide features of our
          Service, to improve and customize our Service. You
          can enable or disable location services when you use
          our Service at any time by way of your device
          settings. Tracking Cookies Data We use cookies and
          similar tracking technologies to track the activity
          on our Service and we hold certain information.
          Cookies are files with a small amount of data which
          may include an anonymous unique identifier. Cookies
          are sent to your browser from a website and stored
          on your device. Other tracking technologies are also
          used such as beacons, tags and scripts to collect
          and track information and to improve and analyze our
          Service. You can instruct your browser to refuse all
          cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not
          be able to use some portions of our Service.
          Examples of Cookies we use:
        </p>
        <ul>
          <li>
            • Session Cookies: We use Session Cookies to
            operate our Service.
          </li>
          <li>
            • Preference Cookies: We use Preference Cookies
            to remember your preferences and various
            settings.
          </li>
          <li>
            • Security Cookies: We use Security Cookies for
            security purposes.
          </li>
          <li>
            • Advertising Cookies: Advertising Cookies are
            used to serve you with advertisements that may
            be relevant to you and your interests.
          </li>
        </ul>
      </div>
      <h6>• Other Data</h6>
      <p>
        While using our Service, we may also collect the
        following information: sex, age, date of birth, place of
        birth, passport details, citizenship, registration at
        place of residence and actual address, telephone number
        (work, mobile), details of documents on education,
        qualification, professional training, employment
        agreements, NDA agreements, information on bonuses and
        compensation, information on marital status, family
        members, social security (or other taxpayer
        identification) number, office location and other data.
      </p>
    </div>
    <div class="privacy-item">
      <h5>5. Security of Data</h5>
      <p>
        The security of your data is important to us but
        remember that no method of transmission over the
        Internet or method of electronic storage is 100% secure.
        While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its
        absolute security.
      </p>
    </div>
  </div>
</section>
<!-- ||Privacy Policy Section End|| -->
