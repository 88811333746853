import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MetaTags {

  public static readonly HOME_TAGS: any [] = [
    {
      "name": "description",
      "content": "Welcome to Apprentice Valley Digital! We are a proud Vodacom Business Partner, providing a full range of mobile, internet voice, and digital enablement solutions for businesses across South Africa. Explore our innovative solutions and experience the power of Vodacom's reliable and efficient services."
    },
    {
      "name": "keywords",
      "content": "Vodacom Business Partner, mobile solutions, internet solutions, voice solutions, digital enablement, South Africa"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Welcome to Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Welcome to Apprentice Valley Digital! We are a proud Vodacom Business Partner, providing a full range of mobile, internet voice, and digital enablement solutions for businesses across South Africa. Explore our innovative solutions and experience the power of Vodacom's reliable and efficient services."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/logo-new.png"
    },
    {
      "property": "og:image:alt",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Welcome to Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Welcome to Apprentice Valley Digital! We are a proud Vodacom Business Partner, providing a full range of mobile, internet voice, and digital enablement solutions for businesses across South Africa. Explore our innovative solutions and experience the power of Vodacom's reliable and efficient services."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/logo.png"
    }
  ]

  public static readonly CONNECTIVITY_TAGS: any [] = [
    {
      "name": "description",
      "content": "Discover the power of Business Internet Fibre with high-speed Internet access for businesses of all sizes. Choose from a range of uncapped offerings, flexible bandwidths, and IP address options. Enjoy additional benefits such as a Huawei DN8245, FREE installations on 24- and 36-month contracts, FREE LTE while you wait, and 50GB Failover services for a limited time. Explore optional fixed voice offerings with IP Talk and 087 numbers. Get connected with our Business Internet Fibre on other networks."
    },
    {
      "name": "keywords",
      "content": "Business Internet Fibre, high-speed Internet access, uncapped offerings, flexible bandwidths, IP address options, Huawei DN8245, FREE installations, LTE while you wait, 50GB Failover services, fixed voice offering, IP Talk, 087 numbers, Business Internet Fibre on other networks"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Business Internet Fibre | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Discover the power of Business Internet Fibre with high-speed Internet access for businesses of all sizes. Choose from a range of uncapped offerings, flexible bandwidths, and IP address options. Enjoy additional benefits such as a Huawei DN8245, FREE installations on 24- and 36-month contracts, FREE LTE while you wait, and 50GB Failover services for a limited time. Explore optional fixed voice offerings with IP Talk and 087 numbers. Get connected with our Business Internet Fibre on other networks."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/business-internet-fibre"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-fibre.png"
    },
    {
      "property": "og:image:alt",
      "content": "Business Internet Fibre"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Business Internet Fibre | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Discover the power of Business Internet Fibre with high-speed Internet access for businesses of all sizes. Choose from a range of uncapped offerings, flexible bandwidths, and IP address options. Enjoy additional benefits such as a Huawei DN8245, FREE installations on 24- and 36-month contracts, FREE LTE while you wait, and 50GB Failover services for a limited time. Explore optional fixed voice offerings with IP Talk and 087 numbers. Get connected with our Business Internet Fibre on other networks."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-fibre.png"
    }
  ]

      public static readonly COMING_SOON_TAGS: any [] = [
    {
      "name": "description",
      "content": "We're working on something awesome! Stay tuned for our upcoming launch and exciting updates. Subscribe to our newsletter to be the first to know."
    },
    {
      "name": "keywords",
      "content": "coming soon, launch, updates, newsletter, stay tuned"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Coming Soon | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "We're working on something awesome! Stay tuned for our upcoming launch and exciting updates. Subscribe to our newsletter to be the first to know."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/coming-soon"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    },
    {
      "property": "og:image:alt",
      "content": "Coming Soon"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Coming Soon | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "We're working on something awesome! Stay tuned for our upcoming launch and exciting updates. Subscribe to our newsletter to be the first to know."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly FAQ_TAGS: any [] = [
    {
      "name": "description",
      "content": "Find answers to frequently asked questions about our products, services, and policies. Browse through our FAQ section to get the information you need."
    },
    {
      "name": "keywords",
      "content": "FAQ, frequently asked questions, customer support, help center"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "FAQ | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Find answers to frequently asked questions about our products, services, and policies. Browse through our FAQ section to get the information you need."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/faq"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    },
    {
      "property": "og:image:alt",
      "content": "FAQ"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "FAQ | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Find answers to frequently asked questions about our products, services, and policies. Browse through our FAQ section to get the information you need."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly BLOG_TAGS: any [] = [
    {
      "name": "description",
      "content": "Read our latest blog posts to stay updated on industry trends, tips, and insights. Explore a variety of topics related to our products, services, and the digital landscape."
    },
    {
      "name": "keywords",
      "content": "blog, articles, industry news, insights, digital trends"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Blog | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Read our latest blog posts to stay updated on industry trends, tips, and insights. Explore a variety of topics related to our products, services, and the digital landscape."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/blog"
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly BLOG_DETAILS_TAGS: any [] = [
    {
      "name": "description",
      "content": "Read the detailed blog post to learn more about a specific topic. Dive deeper into industry trends, tips, and insights related to our products, services, and the digital landscape."
    },
    {
      "name": "keywords",
      "content": "blog details, blog post, in-depth article, industry insights, digital trends"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Blog Details | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Read the detailed blog post to learn more about a specific topic. Dive deeper into industry trends, tips, and insights related to our products, services, and the digital landscape."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/blog-details"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    },
    {
      "property": "og:image:alt",
      "content": "Blog Details"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Blog Details | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Read the detailed blog post to learn more about a specific topic. Dive deeper into industry trends, tips, and insights related to our products, services, and the digital landscape."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly ABOUT_TAGS: any [] = [
    {
      "name": "description",
      "content": "Learn more about Apprentice Valley Digital, our mission, values, and the solutions we offer. Discover how we can help your business grow and succeed in the digital world."
    },
    {
      "name": "keywords",
      "content": "about us, company information, mission statement, values, digital solutions"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "About Us | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Learn more about Apprentice Valley Digital, our mission, values, and the solutions we offer. Discover how we can help your business grow and succeed in the digital world."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/about"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    },
    {
      "property": "og:image:alt",
      "content": "About Us"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "About Us | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Learn more about Apprentice Valley Digital, our mission, values, and the solutions we offer. Discover how we can help your business grow and succeed in the digital world."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly PRIVACY_POLICY_TAGS: any [] = [
    {
      "name": "description",
      "content": "Please review our privacy policy to understand how we collect, use, and protect your personal information. By accessing or using our website, you agree to the terms outlined in our privacy policy."
    },
    {
      "name": "keywords",
      "content": "privacy policy, data protection, personal information, data privacy, website policies"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Privacy Policy | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Please review our privacy policy to understand how we collect, use, and protect your personal information. By accessing or using our website, you agree to the terms outlined in our privacy policy."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/privacy-policy"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/privacy-policy.png"
    },
    {
      "property": "og:image:alt",
      "content": "Privacy Policy"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Privacy Policy | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Please review our privacy policy to understand how we collect, use, and protect your personal information. By accessing or using our website, you agree to the terms outlined in our privacy policy."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/privacy-policy.png"
    }
  ]

  public static readonly TERMS_AND_CONDITIONS_TAGS: any [] = [
    {
      "name": "description",
      "content": "Please read the following terms and conditions carefully before using our services. By accessing or using our website, you agree to be bound by these terms and conditions."
    },
    {
      "name": "keywords",
      "content": "terms and conditions, terms of service, usage agreement, website policies"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Terms and Conditions | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Please read the following terms and conditions carefully before using our services. By accessing or using our website, you agree to be bound by these terms and conditions."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/terms-and-conditions"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    },
    {
      "property": "og:image:alt",
      "content": "Terms and Conditions"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Terms and Conditions | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Please read the following terms and conditions carefully before using our services. By accessing or using our website, you agree to be bound by these terms and conditions."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/terms-and-conditions.png"
    }
  ]

  public static readonly WIRELESS_AIR_AND_SATELLITE_TAGS: any [] = [
    {
      "name": "description",
      "content": "Discover the power of Business Internet Wireless Air & Satellite, a high-speed, asymmetrical fibre-like internet connection for small- and medium-sized businesses. Experience speeds ranging from 4Mbps to 30Mbps, with NO Fair Usage Policy, making it ideal for mobilizing your workforce. Apprentice Valley Business Internet Wireless Air & Satellite provides a reliable and efficient solution for seamless connectivity and enhanced productivity. Explore a wide range of offerings tailored to your business needs and say goodbye to monthly limitations."
    },
    {
      "name": "keywords",
      "content": "Business Internet Wireless Air & Satellite, high-speed internet, asymmetrical connection, fibre-like internet, small businesses, medium-sized businesses, mobilize workforce, 4Mbps, 30Mbps, NO Fair Usage Policy, Apprentice Valley Business Internet, reliable solution, seamless connectivity, enhanced productivity, wide range of offerings, monthly limitations"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Business Internet Wireless Air & Satellite | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Discover the power of Business Internet Wireless Air & Satellite, a high-speed, asymmetrical fibre-like internet connection for small- and medium-sized businesses. Experience speeds ranging from 4Mbps to 30Mbps, with NO Fair Usage Policy, making it ideal for mobilizing your workforce. Apprentice Valley Business Internet Wireless Air & Satellite provides a reliable and efficient solution for seamless connectivity and enhanced productivity. Explore a wide range of offerings tailored to your business needs and say goodbye to monthly limitations."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/business-internet-connectivity-solutions-wireless-and-satellite"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions-wireless-air-and-satellite.png"
    },
    {
      "property": "og:image:alt",
      "content": "Business Internet Wireless Air & Satellite"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Business Internet Wireless Air & Satellite | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Discover the power of Business Internet Wireless Air & Satellite, a high-speed, asymmetrical fibre-like internet connection for small- and medium-sized businesses. Experience speeds ranging from 4Mbps to 30Mbps, with NO Fair Usage Policy, making it ideal for mobilizing your workforce. Apprentice Valley Business Internet Wireless Air & Satellite provides a reliable and efficient solution for seamless connectivity and enhanced productivity. Explore a wide range of offerings tailored to your business needs and say goodbye to monthly limitations."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions-wireless-air-and-satellite.png"
    }
  ]

  public static readonly LTE_AND_SIM_TAGS: any [] = [
    {
      "name": "description",
      "content": "Discover the power of Business Internet LTE and Sim Deals, a faster and more efficient data network for small- and medium-sized businesses, as well as work-from-home employees. Experience speeds up to three times faster than 3G, with Apprentice Valley Business Internet LTE providing a reliable fixed service that enables seamless collaboration and easy file sharing across your office. Explore a wide range of offerings tailored to your business needs, including capped plans with generous daytime data, as well as speed-based plans with a flexible Fair Usage Policy. Benefit from comprehensive packages that include a router, SIM card, and optional dynamic or static IP."
    },
    {
      "name": "keywords",
      "content": "Business Internet LTE AND SIM, faster data network, small businesses, medium-sized businesses, work-from-home employees, LTE speeds, Apprentice Valley Business Internet, fixed service, file sharing, seamless collaboration, capped offers, more data, daytime use, speed-based offers, Fair Usage Policy, router, SIM card, dynamic IP, static IP"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Business Internet LTE And SIM only | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Discover the power of Business Internet LTE, a faster and more efficient data network for small- and medium-sized businesses, as well as work-from-home employees. Experience speeds up to three times faster than 3G, with Apprentice Valley Business Internet LTE providing a reliable fixed service that enables seamless collaboration and easy file sharing across your office. Explore a wide range of offerings tailored to your business needs, including capped plans with generous daytime data, as well as speed-based plans with a flexible Fair Usage Policy. Benefit from comprehensive packages that include a router, SIM card, and optional dynamic or static IP."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/business-internet-connectivity-solutions-lte-and-sim"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions-lte-and-sim.png"
    },
    {
      "property": "og:image:alt",
      "content": "Business Internet LTE & SIM"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Business Internet LTE & SIM | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Discover the power of Business Internet LTE & SIM, a faster and more efficient data network for small- and medium-sized businesses, as well as work-from-home employees. Experience speeds up to three times faster than 3G, with Apprentice Valley Business Internet LTE providing a reliable fixed service that enables seamless collaboration and easy file sharing across your office. Explore a wide range of offerings tailored to your business needs, including capped plans with generous daytime data, as well as speed-based plans with a flexible Fair Usage Policy. Benefit from comprehensive packages"
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions-lte-and-sim.png"
    }
  ]

  public static readonly SMME_TAGS: any [] = [
    {
      "name": "description",
      "content": "We can provide your business with voice and data connectivity to suit your business needs. If you are a small business that just needs email, voice and productivity software or a large enterprise requiring multiple site access and 24/7 support with dedicated secure managed services. Our business connectivity infrastructure provides a resilient platform for your critical business applications, which will help your business perform at its best. Browse our internet solutions to choose the best service for you."
    },
    {
      "name": "keywords",
      "content": "Apprentice Valley, Business Internet Connectivity Solutions, SMME, Business solutions, Connectivity, Business Connectivity"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Business Internet Connectivity Solutions | Apprentice Valley"
    },
    {
      "property": "og:description",
      "content": "We can provide your business with voice and data connectivity to suit your business needs. If you are a small business that just needs email, voice and productivity software or a large enterprise requiring multiple site access and 24/7 support with dedicated secure managed services. Our business connectivity infrastructure provides a resilient platform for your critical business applications, which will help your business perform at its best. Browse our internet solutions to choose the best service for you."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/business-internet-connectivity-solutions"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions.jpg"
    },
    {
      "property": "og:image:alt",
      "content": "Apprentice Valley Business Internet Connectivity Solutions"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_ZA"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Apprentice Valley Business Internet Connectivity Solutions"
    },
    {
      "name": "twitter:description",
      "content": "We can provide your business with voice and data connectivity to suit your business needs. If you are a small business that just needs email, voice and productivity software or a large enterprise requiring multiple site access and 24/7 support with dedicated secure managed services. Our business connectivity infrastructure provides a resilient platform for your critical business applications, which will help your business perform at its best. Browse our internet solutions to choose the best service for you."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-internet-connectivity-solutions.jpg"
    }
  ]

  public static readonly COMMUNICATION_TAGS: any [] = [
    {
      "name": "description",
      "content": "Find the best business contract deals for phones, laptops, and other devices. Browse our selection of contract offers and save on your next technology purchase."
    },
    {
      "name": "keywords",
      "content": "business contract deals, phones, laptops, devices, technology, contract offers"
    },
    {
      "name": "author",
      "content": "Apprentice Valley Digital"
    },
    {
      "name": "viewport",
      "content": "width=device-width, initial-scale=1.0"
    },
    {
      "property": "og:title",
      "content": "Business Contract Deals for Phones, Laptops, and More | Apprentice Valley Digital"
    },
    {
      "property": "og:description",
      "content": "Find the best business contract deals for phones, laptops, and other devices. Browse our selection of contract offers and save on your next technology purchase."
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:url",
      "content": "https://apprenticevalleydigital.co.za/business-contract-deals"
    },
    {
      "property": "og:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-contract-deals-section-1.png"
    },
    {
      "property": "og:image:alt",
      "content": "Business Contract Deals"
    },
    {
      "property": "og:site_name",
      "content": "Apprentice Valley Digital"
    },
    {
      "property": "og:locale",
      "content": "en_za"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:title",
      "content": "Business Contract Deals for Phones, Laptops, and More | Apprentice Valley Digital"
    },
    {
      "name": "twitter:description",
      "content": "Find the best business contract deals for phones, laptops, and other devices. Browse our selection of contract offers and save on your next technology purchase."
    },
    {
      "name": "twitter:image",
      "content": "https://apprenticevalleydigital.co.za/assets/images/business-contract-deals-section-1.png"
    }
  ]
}
