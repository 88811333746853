<div class="spinner-6" *ngIf="isLoading"></div>
<div [hidden]="isLoading">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-cookie-consent-modal *ngIf="showCookieConsentModal"
                            (acceptCookies)="onAcceptAllCookies()"
                            (declineCookies)="onDeclineAllCookies()">
  </app-cookie-consent-modal>
</div>
