import { Component } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {MetaTags} from "../../constants/meta-data/meta.tags";

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent {
  constructor(private meta: Meta,
              private title: Title) {

    this.meta.addTags(MetaTags.BLOG_DETAILS_TAGS);
    this.setTitle('Blog Details | Apprentice Valley Digital');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}
