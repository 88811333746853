<!-- ||Mobile Nav Section End|| -->
<!-- Header Section Start -->
<header class="privacy-header">

  <section id="banner" class="psivacy-banner">
    <div class="container text-center">
      <h1>Frequently Asked Questions</h1>
    </div>
  </section>
  <div class="overlay-btn">
    <a routerLink="/home">Home </a>/
    <a routerLink="/faq">Frequently Asked Questions</a>
  </div>
</header>
<!-- ||Header Section End|| -->

<!-- Privacy Policy Section Start -->
<div id="faq">
  <div class="container">
    <div
      class="accordion accordion-flush"
      id="accordionFlushExample"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Q1. Do you have a Service Level Agreement (SLA),
            and if so what is it?
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            An SLA outlines a company’s quality commitment.
            Network expectations like downtime, latency, and
            jitter are addressed to ensure performance
            levels are met. If your revenue is dependant on
            connectivity, you want to feel confident that
            you’re getting a reliable connection.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            Q2. What is your Mean Time to Repair (MTTR)?
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            In the event of an outage, how long will it take
            the internet service provider to get your
            network back up and running? For comparison,
            typical DSL connections take 24-48 hours to
            repair. Unfortunately, many companies will only
            tell you how long it takes before you get a
            response – never mind a resolution.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            Q3. What is your tech support like?
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            What are the support hours? Are they level 1
            (L1) technicians, or are they L3 and do they understand
            complex systems? How many calls or escalations
            does it take before you get a resolution? You
            should have a good understanding of how the service
            provider performed at resolving previous customer queries
            before they can resolve yours.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            Q4. Where is your support team based?
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Can you easily reach tech support to help you?
            Make sure you aren’t going to waste time being
            kept on-hold for a technician that can’t walk you through
            every step of the way to resolve your query.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive"
            aria-expanded="false"
            aria-controls="flush-collapseFive"
          >
            Q5. Are there any other fees?
          </button>
        </h2>
        <div
          id="flush-collapseFive"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Installation costs, gateway costs, cancellation
            fees, and any cross-connect fees may be hidden
            in the fine print. Before signing a contract, be
            sure you’re aware of the cost breakdown.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix"
            aria-expanded="false"
            aria-controls="flush-collapseSix"
          >
            Q6. Do you have managed options?
          </button>
        </h2>
        <div
          id="flush-collapseSix"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            Can the connection be monitored proactively? Can
            issues be resolved remotely? Though technically
            this option is a value-add, you can save your IT team
            a lot of headaches by letting your ISP manage your
            router, connectivity, failover and more. Can the
            connection be monitored proactively? Can issues
            be resolved remotely?
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ||Privacy Policy Section End|| -->
