<div class="footer-container">
    <div class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="m-1 col-md-2 col-sm-6">
                  <div class="flex-row" >

                    <div class="logo-container">
                      <img src="assets/images/logo-v3.png" alt="Logo" width="85px" >
                    </div>
                    <div class="col-md-6">
                      <div class="social-media-container d-flex justify-content-between">

                        <a class="m-2 " href="https://www.facebook.com/ApprenticeValleyDigital/"><span class="fab fa-facebook-f"></span></a>
                        <a class="m-2" href="https://twitter.com/AValleyDigital"><img src="assets/images/X_logo_2023_(white).png" alt="Twitter" id="twitter-icon"></a>
                        <a class="m-2 " href="https://www.linkedin.com/company/apprentice-valley-digital"><img src="assets/images/icons8-linkedin-30.png" alt="linkedIn" id="linkedIn-icon"></a>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <div class="footer-item address-section">
                        <div class="bold">Address</div>
                        <div style="line-height: 28px;">Suite 101-104 <br>Winnie Mandela Drive <br> 1st Floor <br> Design Quarter <br>
                            Fourways <br> Johannesburg <br> 2191
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <div class="footer-item company-section">
                        <div class="bold" style="margin-bottom: 5px;">Company</div>
                        <div class="footer-link" [routerLink]="['about']">About Us</div>
                        <a href="https://careers.ocean76.com/" class="footer-link">Careers</a>
<!--                        <div class="footer-link" [routerLink]="['blog']">Blogs</div>-->
                        <div class="footer-link" [routerLink]="['faq']">FAQs</div>
                        <div class="footer-link" [routerLink]="['privacy-policy']">Privacy Policy</div>
                        <div class="footer-link" [routerLink]="['terms-and-conditions']">Terms and Conditions</div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <div class="footer-item company-section" *ngIf="showMap">
                      <google-map [options]="{
                            mapTypeControl: false,
                            streetViewControl: false
                          }" [center]="{lat: -26.030451, lng: 28.0100347}" [zoom]="15" width="400px" height="200px">
                        <map-marker [position]="{lat: -26.030451, lng: 28.0100347}"></map-marker>
                      </google-map>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>
